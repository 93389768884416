import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ProjectSiteEditorCard from '../ProjectSiteEditorCard/Loadable'

import ProjectEditorStyled from './styledComponent'

import emptyCaseImg from '../../asset/images/emptyCase.svg'
import MESSAGE from './message'

class ProjectEditor extends PureComponent {
  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  getHeaderTitle() {
    return (
      <div className="project-edtior-header-container">
        <div className="project-editor-header-title">{this.getMessage('header_col_title')}</div>
        <div className="project-editor-header-total">{this.getMessage('header_col_area')}</div>
        <div className="project-editor-header-total">{this.getMessage('header_col_camera')}</div>
      </div>
    )
  }

  renderSiteList() {
    const siteList = this.props.listSite.map((siteData) => {
      return (
        <ProjectSiteEditorCard
          key={siteData.site_id}
          siteID={siteData.site_id}
          name={siteData.name}
          listArea={siteData.areas}
          screenshot={siteData.pre_encode_image}
          onUpdateAreaName={this.props.onUpdateAreaName}
          onAddCameraBetweenArea={this.props.onAddCameraBetweenArea}
          onUpdateSiteName={this.props.onUpdateSiteName}
          onUpdateSiteScreenshot={this.props.onUpdateSiteScreenshot}
          onDeleteSite={this.props.onDeleteSite}
          onCreateNewCamera={this.props.onCreateNewCamera}
          onCreateNewArea={this.props.onCreateNewArea}
          onDeleteArea={this.props.onDeleteArea}
          onDeleteCamera={this.props.onDeleteCamera}
          onUpdateCameraData={this.props.onUpdateCameraData}
          onCreateSpeaker={this.props.onCreateSpeaker}
          onUpdateSpeaker={this.props.onUpdateSpeaker}
          onDeleteSpeaker={this.props.onDeleteSpeaker}
          currentUser={this.props.currentUser}
          isRecalculatingZone={this.props.isRecalculatingZone}
        />
      )
    })
    return siteList
  }

  getEmptyList() {
    return (
      <div className="project-editor-empty-list-container">
        <div className="project-editor-empty-list-image">
          <img src={emptyCaseImg} alt="" />
        </div>
        <div className="project-editor-empty-list-message">{this.getMessage('empty_case_txt')}</div>
      </div>
    )
  }

  getProjectEditorBodyClassName() {
    let className = 'project-editor-body-container'
    if (this.props.listSite.length === 0) {
      className += ' empty-list'
    }
    return className
  }

  getBodySiteList() {
    const output = this.props.listSite.length === 0 ? this.getEmptyList() : this.renderSiteList()
    return <div className={this.getProjectEditorBodyClassName()}>{output}</div>
  }

  render() {
    return (
      <ProjectEditorStyled>
        {this.getHeaderTitle()}
        {this.getBodySiteList()}
      </ProjectEditorStyled>
    )
  }
}

ProjectEditor.propTypes = {
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    firstname: PropTypes.string,
    language: PropTypes.string,
    lastname: PropTypes.string,
    manager: PropTypes.bool,
    token: PropTypes.string
  }),
  language: PropTypes.string,
  listSite: PropTypes.arrayOf(
    PropTypes.shape({
      site_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      areas: PropTypes.array.isRequired
    })
  ).isRequired,
  onDeleteSite: PropTypes.func.isRequired,
  onUpdateSiteScreenshot: PropTypes.func.isRequired,
  onUpdateSiteName: PropTypes.func.isRequired,
  onUpdateAreaName: PropTypes.func.isRequired,
  onAddCameraBetweenArea: PropTypes.func.isRequired,
  onCreateNewCamera: PropTypes.func.isRequired,
  onCreateNewArea: PropTypes.func.isRequired,
  onDeleteArea: PropTypes.func.isRequired,
  onDeleteCamera: PropTypes.func.isRequired,
  onUpdateCameraData: PropTypes.func.isRequired,
  onCreateSpeaker: PropTypes.func.isRequired,
  onUpdateSpeaker: PropTypes.func.isRequired,
  onDeleteSpeaker: PropTypes.func.isRequired,
  isRecalculatingZone: PropTypes.bool
}

export default ProjectEditor
