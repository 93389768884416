const MESSAGE = {
  EN: {
    header_col_title: 'TITLE',
    header_col_area: 'AREA',
    header_col_camera: 'CAMERA',
    empty_case_txt: 'Nothing to Show'
  },
  TH: {
    header_col_title: 'ชื่อ',
    header_col_area: 'พื้นที่',
    header_col_camera: 'กล้อง',
    empty_case_txt: 'ไม่มีข้อมูล'
  }
}

export default MESSAGE
