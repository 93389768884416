import styled from 'styled-components'

export default styled.div`
  width: 100%;
  .project-edtior-header-container {
    width: 100%;
    position: relative;
    display: flex;
    padding: 0 25px 18px 25px;
    font-family: 'Prompt', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.5;
    letter-spacing: normal;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
    .project-editor-header-title {
      width: 324px;
      padding-left: 20px;
      margin-left: 60px;
      font-size: inherit;
    }
    .project-editor-header-total {
      width: 104px;
      text-align: center;
      font-size: inherit;
    }
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .project-editor-body-container {
    width: 100%;
    padding: 0 25px;
    &.empty-list {
      margin-top: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      .project-editor-empty-list-container {
        width: 200px;
        .project-editor-empty-list-image {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
          img {
            width: 50px;
            height: 37px;
            object-fit: contain;
          }
        }
        .project-editor-empty-list-message {
          font-family: 'Prompt', sans-serif;
          font-size: 24px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: white;
          text-align: center;
        }
      }
    }
  }
`
